import React, { useMemo, Fragment, useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Button } from 'react-bulma-components'
import SEO from '../components/SEO'
import WasThisHelpful from '../components/WasThisHelpful'
import Aside from '../components/AsideMdx'
import Content from '../components/Content'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import IconInline from '../components/IconInline'

const sortNodesByName = (x, y) => {
  if (x.title < y.title) {
    return -1
  }
  if (x.title > y.title) {
    return 1
  }
  return 0
}

const categories = [
  {
    label: 'Triggers',
    key: 'Trigger Node',
    anchor: 'triggers',
    color: '#8db319',
  },
  {
    label: 'Debug Nodes',
    key: 'Debug Node',
    anchor: 'debug-nodes',
    color: '#707070',
  },
  {
    label: 'Experience Nodes',
    key: 'Experience Node',
    anchor: 'experience-nodes',
    color: '#ff5700',
  },
  {
    label: 'Logic Nodes',
    key: 'Logic Node',
    anchor: 'logic-nodes',
    color: '#02cae5',
  },
  {
    label: 'Data Nodes',
    key: 'Data Node',
    anchor: 'data-nodes',
    color: '#825bff',
  },
  {
    label: 'Output Nodes',
    key: 'Output Node',
    anchor: 'output-nodes',
    color: '#ff9a25',
  },
]

// based on structure passed into src/components/AsideMdx.js
const toc = {
  items: [
    {
      items: categories.map(({ label, anchor }) => {
        return {
          title: label,
          url: `#${anchor}`,
        }
      }),
    },
  ],
}

const FlowCategoryIndicator = ({ color }) => {
  return (
    <span
      css={css`
        width: 12px;
        height: 12px;
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        background: ${color};
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 5px;
      `}
    />
  )
}

const ALL_FLOW_CLASSES = 'All Flow Classes'
const flowClasses = ['Application', 'Experience', 'Edge', 'Embedded']

const WorkflowNodesSummary = ({ data, location }) => {
  const { mdx: post } = data
  const [selectedFilter, setSelectedFilter] = useState(ALL_FLOW_CLASSES)
  const onFilterClick = useCallback((e) => {
    setSelectedFilter(e.currentTarget.dataset.filter)
  }, [])
  const nodesByCategory = useMemo(() => {
    const nodes = [...data.allMdx.edges, ...data.allMarkdownRemark.edges].map(
      ({
        node: {
          frontmatter,
          fields: { slug },
        },
      }) => {
        return {
          ...frontmatter,
          slug,
          flowclass: frontmatter.flowclass.map((cl) => {
            // strips the agent version number
            return cl.split(' ')[0]
          }),
        }
      }
    )
    const categoryMap = {}
    nodes.forEach((node) => {
      const { category } = node
      if (!categoryMap[category]) {
        categoryMap[category] = []
      }
      if (
        selectedFilter === ALL_FLOW_CLASSES ||
        node.flowclass.includes(selectedFilter)
      )
        categoryMap[category].push(node)
    })
    Object.values(categoryMap).forEach((arr) => {
      arr.sort(sortNodesByName)
    })
    return categoryMap
  }, [data, selectedFilter])
  return (
    <>
      <Nav location={location} />
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          padding: 40px 0px 40px 64px;
          margin: auto;
          max-width: 1200px;

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }
        `}
      >
        <SEO
          description={post.frontmatter.description}
          title={post.frontmatter.title}
          slug={post.fields.slug}
          data-category={post.frontmatter.category}
        />
        <Content>
          <main
            id="main"
            data-category={post.frontmatter.category}
            data-slug={post.fields.slug}
          >
            <MDXRenderer>{post.body}</MDXRenderer>
            <Button.Group hasAddons size="small">
              <Button
                data-filter={ALL_FLOW_CLASSES}
                isSelected={selectedFilter === ALL_FLOW_CLASSES}
                onClick={onFilterClick}
              >
                {ALL_FLOW_CLASSES}
              </Button>
              {flowClasses.map((flowClass) => (
                <Button
                  key={flowClass}
                  data-filter={flowClass}
                  onClick={onFilterClick}
                  isSelected={selectedFilter === flowClass}
                >
                  <IconInline
                    glyph={`FLOW_CLASS_${flowClass.toUpperCase()}`}
                    css={css`
                      position: relative;
                      top: 1px;
                    `}
                  />
                  {flowClass}
                </Button>
              ))}
            </Button.Group>
            {categories.map(({ label, key, anchor, color }) => (
              <Fragment key={key}>
                <h2
                  id={anchor}
                  css={css`
                    margin: 50px 0 15px;
                    &:first-of-type {
                      margin-top: 15px;
                    }
                  `}
                >
                  <FlowCategoryIndicator color={color} />
                  {label}
                </h2>
                {nodesByCategory[key].length ? (
                  <ul
                    css={css`
                      margin: 0;
                      padding: 0;
                      list-style: none;
                    `}
                  >
                    {nodesByCategory[key].map((node) => (
                      <li
                        key={node.slug}
                        css={css`
                          margin: 0 0 30px !important;
                          padding: 0;
                          list-style: none;
                        `}
                      >
                        <h3
                          css={css`
                            font-size: 18px;
                            margin: 0;
                          `}
                        >
                          <a
                            href={node.slug}
                            css={css`
                              color: #111a2b !important;
                            `}
                          >
                            {node.title}
                          </a>
                          {node.flowclass.map((flcs) => {
                            return (
                              <IconInline
                                key={flcs}
                                glyph={`FLOW_CLASS_${flcs.toUpperCase()}`}
                                title={flcs}
                                trailing
                                fill="#888c95"
                              />
                            )
                          })}
                        </h3>
                        {node.description}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p
                    css={css`
                      color: #444;
                      font-style: italic !important;
                    `}
                  >
                    No nodes in this category match your selected filter.
                  </p>
                )}
              </Fragment>
            ))}
          </main>
          <WasThisHelpful />
          <Footer />
        </Content>
        <Aside tableOfContents={toc} />
      </div>
    </>
  )
}

export const query = graphql`
  query GetWorkflowNodesSummary($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        description
        flowclass
        category
      }
      fields {
        slug
      }
    }
    allMdx(
      filter: {
        frontmatter: { deprecated: { ne: true }, flowclass: { ne: null } }
      }
    ) {
      edges {
        node {
          frontmatter {
            flowclass
            title
            description
            category
          }
          fields {
            slug
          }
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { flowclass: { ne: null } } }) {
      edges {
        node {
          frontmatter {
            flowclass
            title
            description
            category
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default WorkflowNodesSummary
